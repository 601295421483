import React from 'react'
// Types
import { CustomerStoryPreview } from '../../../../types/customerStories'
// Components
import { Link } from 'gatsby'
import { Box, Text, Image, Button } from '../../../../atoms'
import { ArticleCardImageWrapper, ArticleCardTitle, ArticleCardExcerpt } from './StoryCard.styled'

interface StoryCardProps {
  data: CustomerStoryPreview
}

const StoryCard: React.FC<StoryCardProps> = ({ data }) => {
  const { slug, title, excerpt, thumbnail, categories } = data

  return (
    <Box as={'article'}>
      <Box borderRadius={'regular'} overflow={'hidden'} zIndex={1}>
        <Link to={`/${slug}`} style={{ display: 'block' }}>
          <ArticleCardImageWrapper position={'relative'}>
            <Image
              draggable={false}
              alt={thumbnail.alt || title}
              image={thumbnail.gatsbyImageData}
              style={{
                borderRadius: '16px',
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              imgStyle={{ borderRadius: '16px' }}
            />
          </ArticleCardImageWrapper>
        </Link>
      </Box>
      <Box mt={5}>
        {categories && categories.length > 0 && (
          <Box mb={2}>
            {categories.map((c, i) => (
              <Link key={c.id} to={`/${c.pageInfo.slug}`}>
                <Text as={'span'} color={'purpleBlue'} fontSize={13} fontWeight={'semi'} textTransform={'uppercase'}>
                  {c.name}
                </Text>
                {i + 1 < categories.length && (
                  <Box display={'inline'} mx={1}>
                    <Text as={'span'} fontSize={13}>
                      {'•'}
                    </Text>
                  </Box>
                )}
              </Link>
            ))}
          </Box>
        )}
        <Link to={`/${slug}`} style={{ display: 'block' }}>
          <Box mb={2} minHeight={54}>
            <ArticleCardTitle fontWeight={'semi'} lineHeight={1.5}>
              {title}
            </ArticleCardTitle>
          </Box>
          <Box minHeight={84}>
            <ArticleCardExcerpt fontSize={2} lineHeight={'28px'}>
              {excerpt}
            </ArticleCardExcerpt>
          </Box>
          <Box mt={[4, 6]}>
            <Button buttonType={'text'} label={'Read story'} color={'dark'} />
          </Box>
        </Link>
      </Box>
    </Box>
  )
}

export default StoryCard
