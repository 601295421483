import React from 'react'
// Types
import { PublicationFormattedProps } from '../../../types/pages'
import { AuthorProps } from '../../../types/blog'
import { CustomerStoriesCategory } from '../../../types/customerStories'
// Components
import { Box, Flex, Image, Text } from '../../../atoms'
import { StoryAuthorDate } from '../commons'
import ShareBar from '../../blog/ShareBar'
import { getUrlToShare } from '../../../utils/commons'
import { Link } from 'gatsby'

interface CustomerStoryHeroProps {
  title: string
  author: AuthorProps
  categories: CustomerStoriesCategory[]
  metaFormatted: PublicationFormattedProps
}

const CustomerStoryHero: React.FC<CustomerStoryHeroProps> = (props) => {
  const { title, author, categories, metaFormatted } = props
  return (
    <Box as={'section'}>
      <Box mb={2}>
        {categories.map((c, i) => (
          <Link key={c.id} to={`/${c.pageInfo.slug}`}>
            <Text as={'span'} color={'purpleBlue'} fontSize={13} fontWeight={'semi'} textTransform={'uppercase'}>
              {c.name}
            </Text>
            {i + 1 < categories.length && (
              <Box display={'inline'} mx={1}>
                <Text as={'span'} fontSize={13}>
                  {'•'}
                </Text>
              </Box>
            )}
          </Link>
        ))}
      </Box>
      <Box>
        <Text
          as={'h1'}
          fontSize={['28px', '34px', '40px']}
          fontWeight={'semi'}
          lineHeight={['34px', '40px', '48px']}
          fontFamily={'hoves'}
        >
          {title}
        </Text>
      </Box>
      <Flex justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} mt={[4, 5, 6]}>
        <Box pt={4} flex={'1 0 auto'} pr={4}>
          <StoryAuthorDate small author={author} date={metaFormatted.firstPublishedAt || metaFormatted.createdAt} />
        </Box>
        <Box flex={'0 0 auto'} pt={4}>
          <ShareBar urlToShare={getUrlToShare()} direction={'row'} />
        </Box>
      </Flex>
    </Box>
  )
}

export default CustomerStoryHero
