import React from 'react'
// Types
import { CustomerStoryPreview } from '../../../types/customerStories'
// Components
import { Box, Heading, Image, Text, Button } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Link } from 'gatsby'
import { CustomerStoryCardTitle, CustomerStoryCardExcerpt } from './CustomerStoriesLandingHero.styled'

interface CustomerStoriesLandingHeroProps {
  headline: string
  customerStories: CustomerStoryPreview[]
  bgColor: string
}

const CustomerStoriesLandingHero: React.FC<CustomerStoriesLandingHeroProps> = ({
  headline,
  bgColor,
  customerStories,
}) => {
  return (
    <>
      <Box
        as={'section'}
        bg={bgColor}
        pt={[120, 140, 160]}
        pb={[220, 240, 280]}
        backgroundImage={[
          'radial-gradient(circle at 0% 80%,rgba(139,1,232,0.16),rgba(255,255,255,0) 50%),radial-gradient(circle at 50% -10%,rgba(248,144,144,0.12),rgba(255,255,255,0) 20%),radial-gradient(circle at 100% 40%,rgba(109,216,252,0.12),rgba(255,255,255,0) 48%)',
          'radial-gradient(circle at 0% 80%,rgba(139,1,232,0.16),rgba(255,255,255,0) 20%),radial-gradient(circle at 50% 65%,rgba(248,144,144,0.12),rgba(255,255,255,0) 20%),radial-gradient(circle at 100% 70%,rgba(109,216,252,0.12),rgba(255,255,255,0) 18%)',
        ]}
      >
        <Grid>
          <Row>
            <Col xs={12}>
              <Heading as={'h3'} color={'white'}>
                {headline}
              </Heading>
            </Col>
          </Row>
        </Grid>
      </Box>
      <Box mt={-200}>
        <Grid>
          <Row>
            {customerStories.map((c) => {
              return (
                <Col key={c.id} xs={12} sm={4}>
                  <Box mt={[7, 0, 0]}>
                    <Link to={`/${c.slug}`} style={{ display: 'block' }}>
                      <Image
                        alt={c.customerLogo.alt || ''}
                        image={c.customerLogo.gatsbyImageData}
                        imgStyle={{ width: '100%' }}
                      />
                    </Link>
                  </Box>
                  <Box mt={6}>
                    {c.categories && c.categories.length > 0 && (
                      <Box mb={2}>
                        {c.categories.map((cat, i) => (
                          <Link key={cat.id} to={`/${cat.pageInfo.slug}`}>
                            <Text
                              as={'span'}
                              color={'purpleBlue'}
                              fontSize={13}
                              fontWeight={'semi'}
                              textTransform={'uppercase'}
                            >
                              {cat.name}
                            </Text>
                            {i + 1 < c.categories.length && (
                              <Box display={'inline'} mx={1}>
                                <Text as={'span'} fontSize={13}>
                                  {'•'}
                                </Text>
                              </Box>
                            )}
                          </Link>
                        ))}
                      </Box>
                    )}
                    <Link to={`/${c.slug}`} style={{ display: 'block' }}>
                      <Box mb={2} minHeight={54}>
                        <CustomerStoryCardTitle fontWeight={'semi'} lineHeight={1.5}>
                          {c.title}
                        </CustomerStoryCardTitle>
                      </Box>
                      <Box minHeight={84}>
                        <CustomerStoryCardExcerpt fontSize={2} lineHeight={'28px'}>
                          {c.excerpt}
                        </CustomerStoryCardExcerpt>
                      </Box>
                      <Box mt={6}>
                        <Button buttonType={'text'} label={'Read story'} color={'dark'} />
                      </Box>
                    </Link>
                  </Box>
                </Col>
              )
            })}
          </Row>
        </Grid>
      </Box>
    </>
  )
}

export default CustomerStoriesLandingHero
