import React from 'react'
// Types
import { AuthorProps } from '../../../../types/blog'
//Components
import { Box, Flex, Image, Text } from '../../../../atoms'

interface ArticleAuthorDateProps {
  author: AuthorProps
  date: string
  small?: boolean
  light?: boolean
}

const StoryAuthorDate: React.FC<ArticleAuthorDateProps> = ({ author, date, small = true, light = false }) => {
  return (
    <Flex alignItems={'center'}>
      <Flex
        mr={3}
        justifyContent={'center'}
        alignItems={'center'}
        flexGrow={0}
        flexShrink={0}
        flexBasis={small ? 48 : [48, 48, 56]}
        height={small ? 48 : [48, 48, 56]}
        borderRadius={'circle'}
        overflow={'hidden'}
        bg={!author.profilePicture ? 'dark' : undefined}
        zIndex={1}
      >
        {author.profilePicture ? (
          <Image
            draggable={false}
            alt={author.profilePicture.alt || author.name}
            image={author.profilePicture.gatsbyImageData}
            style={{ width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden', borderRadius: '9999px' }}
            imgStyle={{ borderRadius: '9999px' }}
          />
        ) : (
          <Box position={'relative'} width={'100%'} height={'100%'} overflow={'hidden'}>
            <Box
              position={'absolute'}
              width={16}
              height={16}
              top={'9px'}
              left={'50%'}
              borderRadius={'circle'}
              bg={'white'}
              opacity={0.32}
              style={{
                transform: 'translateX(-50%)',
              }}
            />
            <Box
              position={'absolute'}
              width={38}
              height={48}
              top={'30px'}
              left={'50%'}
              borderRadius={'circle'}
              bg={'white'}
              opacity={0.32}
              style={{
                transform: 'translateX(-50%)',
              }}
            />
          </Box>
        )}
      </Flex>
      <Box flex={'1'}>
        <Text fontSize={1} lineHeight={1.4} color={light ? 'white' : 'dark'}>
          <Text
            as={'span'}
            fontSize={'inherit'}
            fontWeight={'semi'}
            lineHeight={'inherit'}
            color={light ? 'white' : 'dark'}
          >
            {author.name}
          </Text>
          <Text as={'span'} fontSize={'inherit'} lineHeight={'inherit'} opacity={0.48} color={'inherit'}>
            {' • '}
          </Text>
          <Text as={'span'} fontSize={'inherit'} lineHeight={'inherit'} color={'inherit'}>
            {date}
          </Text>
        </Text>
      </Box>
    </Flex>
  )
}
export default StoryAuthorDate
