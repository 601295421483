import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageInfoProps, PaginationContextProps, PublicationProps } from '../../types/pages'
import {
  CustomerStoriesCategory as CustomerStoriesCategoryProps,
  CustomerStoryPreview,
} from '../../types/customerStories'
// Components
import { Box } from '../../atoms'
import { Layout, Seo } from '../../components/commons'
import { StoriesHeaderWithCategories } from '../../components/customerStories'
import { CustomerStoriesPaginatedStories } from '../../components/customerStories/commons'

interface DataQuery {
  customerStoriesUtils: {
    pageInfo: PageInfoProps
    storiesListLabel: string
    allStoriesLabel: string
    paginationPreviousLabel: string
    paginationNextLabel: string
  }
  category: CustomerStoriesCategoryProps & {
    locale: string
    meta: PublicationProps
  }
  categoryList: {
    nodes: CustomerStoriesCategoryProps[]
  }
  customerStoriesList: {
    nodes: CustomerStoryPreview[]
  }
}

interface PageContext extends PaginationContextProps {
  categoryId: string
  previousPagePath: string
  nextPagePath: string
}

const CustomerStoriesCategory: React.FC<PageProps<DataQuery, PageContext>> = ({ pageContext, data }) => {
  const { totalPages, currentPage, previousPagePath, nextPagePath } = pageContext
  const {
    customerStoriesUtils: {
      pageInfo: customerStoriesPageInfo,
      storiesListLabel,
      allStoriesLabel,
      paginationPreviousLabel,
      paginationNextLabel,
    },
    category: { locale, meta, pageInfo },
    categoryList: { nodes: categories },
    customerStoriesList: { nodes: stories },
  } = data

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />

      <Box as={'section'} pt={120}>
        <StoriesHeaderWithCategories
          title={storiesListLabel}
          customerStoriesLandingPageInfo={customerStoriesPageInfo}
          allLabel={allStoriesLabel}
          categories={categories}
        />
        <CustomerStoriesPaginatedStories
          stories={stories}
          prevLabel={paginationPreviousLabel}
          previousPagePath={previousPagePath}
          nextLabel={paginationNextLabel}
          nextPagePath={nextPagePath}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query CustomerStoriesCategoryQuery($categoryId: String!, $skip: Int!, $limit: Int!) {
    customerStoriesUtils: datoCmsCustomerStoriesLanding {
      pageInfo {
        ...pageInfoFragment
      }
      storiesListLabel
      allStoriesLabel
      paginationPreviousLabel
      paginationNextLabel
    }
    category: datoCmsCustomerStoriesCategory(id: { eq: $categoryId }) {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      ...customerStoriesCategoryFragment
    }
    categoryList: allDatoCmsCustomerStoriesCategory(sort: { fields: name }) {
      nodes {
        ...customerStoriesCategoryFragment
      }
    }
    customerStoriesList: allDatoCmsCustomerStory(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: { categories: { elemMatch: { id: { eq: $categoryId } } } }
    ) {
      nodes {
        ...customerStoryPreviewWithCategoriesFragment
      }
    }
  }
`

export default CustomerStoriesCategory
