import React from 'react'
import { Text, TextProps } from '../../../atoms'
import styled from 'styled-components'

export const CustomerStoryCardTitle: React.FC<TextProps> = styled(Text)<TextProps>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const CustomerStoryCardExcerpt: React.FC<TextProps> = styled(Text)<TextProps>`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
