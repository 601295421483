import React from 'react'
// Types
import { PaginationProps } from '../../../../types/pages'
import { CustomerStoryPreview } from '../../../../types/customerStories'
// Components
import { Link } from 'gatsby'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Button } from '../../../../atoms'
import { StoryCard } from '../../commons'

interface CustomerStoriesPaginatedStoriesProps extends PaginationProps {
  title?: string
  stories: CustomerStoryPreview[]
  prevLabel?: string
  nextLabel?: string
}

const CustomerStoriesPaginatedStories: React.FC<CustomerStoriesPaginatedStoriesProps> = ({
  title,
  stories,
  prevLabel = 'Prev',
  previousPagePath,
  nextLabel = 'Next',
  nextPagePath,
  totalPages,
  currentPage,
}) => {
  return (
    <Box as={'section'} mb={[100, 120]} mt={title ? [100, 120] : [48, 64]}>
      <Grid>
        <Row>
          <Col xs={12}>
            {/* Title */}
            {title && (
              <Box mb={[0, 0, 6]}>
                <Heading as={'h4'}>{title}</Heading>
              </Box>
            )}
            {/* Articles */}
            {stories.length > 0 ? (
              <Row>
                {stories.map((story: CustomerStoryPreview) => (
                  <Col key={story.id} xs={12} sm={6} md={4}>
                    <Box mb={[7, 8, 10]}>
                      <StoryCard data={story} />
                    </Box>
                  </Col>
                ))}
              </Row>
            ) : (
              <Box minHeight={'400px'}>
                <Text fontSize={3} opacity={0.7} textAlign={'center'}>
                  No post
                </Text>
              </Box>
            )}
            {/* Pagination */}
            {!!(totalPages && totalPages > 0) && (
              <Flex mt={4} justifyContent={['space-between', 'center']} alignItems={'center'}>
                <Box flex={'0 0 auto'} mr={4}>
                  <Button
                    label={prevLabel}
                    size={'sm'}
                    buttonType={'outline'}
                    disabled={!previousPagePath}
                    link={
                      previousPagePath
                        ? { id: 'blog-pagination-revPath', name: prevLabel, slug: previousPagePath }
                        : undefined
                    }
                  />
                </Box>
                {previousPagePath && currentPage && (
                  <Box display={['none', 'block']} flex={'0 0 auto'}>
                    <Link to={`/${previousPagePath}`}>
                      <Flex
                        mx={1}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={48}
                        height={48}
                        borderRadius={'circle'}
                      >
                        <Text textAlign={'center'} fontSize={2} fontWeight={'bold'}>
                          {currentPage - 1}
                        </Text>
                      </Flex>
                    </Link>
                  </Box>
                )}
                {currentPage && (
                  <Flex
                    flex={'0 0 auto'}
                    mx={1}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bg={'purpleBlue'}
                    width={48}
                    height={48}
                    borderRadius={'circle'}
                  >
                    <Text textAlign={'center'} color={'white'} fontSize={2} fontWeight={'bold'}>
                      {currentPage}
                    </Text>
                  </Flex>
                )}
                {nextPagePath && currentPage && (
                  <Box display={['none', 'block']} flex={'0 0 auto'}>
                    <Link to={`/${nextPagePath}`}>
                      <Flex
                        mx={1}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={48}
                        height={48}
                        borderRadius={'circle'}
                      >
                        <Text textAlign={'center'} fontSize={2} fontWeight={'bold'}>
                          {currentPage + 1}
                        </Text>
                      </Flex>
                    </Link>
                  </Box>
                )}
                <Box flex={'0 0 auto'} ml={4}>
                  <Button
                    label={nextLabel}
                    size={'sm'}
                    buttonType={'outline'}
                    disabled={!nextPagePath}
                    link={
                      nextPagePath ? { id: 'blog-pagination-revPath', name: nextLabel, slug: nextPagePath } : undefined
                    }
                  />
                </Box>
              </Flex>
            )}
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}
export default CustomerStoriesPaginatedStories
